<!-- <app-layout></app-layout> -->

<div class="content">
    <div class="justify-content-between m-0 row">
        <div class="align-items-center border rounded col-auto d-flex mb-2">
            <span>Total: <strong>{{totalCount || 0}}</strong></span>
        </div>
        <div class="col-auto mb-2 news-header" (click)="openAddSportModel(template,'Premium')">
            <button class="btn btn-create">
                <img src="../../../assets/ludus_icon/black/plush-icon.svg">
                Add Sport
            </button>
        </div>
    </div>
    <div class="table-responsive mb-3">
        <table class="table border table-hover">
            <thead>
                <tr>
                    <th scope="col">S No.</th>
                    <th scope="col">Sport Name</th>
                    <th scope="col" >Icon</th>
                    <th scope="col">Action</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of sportList; let i = index">
                    <td>{{((currentPage-1)*itemsPerPage)+(i+1)}}.</td>
                    <td><span class="text-short">{{item.name?item.name :'-'}}</span></td>
                    <td>
                        <img *ngIf="item.icon" src="{{item.icon}}" width="40px" height="40px" class="rounded" (click)="enlargeImg(enLgImg,item.icon)">
                    </td>
                    <td>
                        <span style="cursor: pointer;margin-right: 10px;" (click)="openEditSport(template,item)">
                            <img src="../../../assets/icons/edit-icon.svg" />
                        </span>
                        <span  style="cursor: pointer;margin-right: 10px;" (click)="openDeleteSport(delModel, item._id)">
                            <img src="../../../assets/icons/delete-icon.svg" />
                        </span>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="d-flex justify-content-center" *ngIf="notFound">
        <img src="../../../assets/images/not-found.svg" >
    </div>

    <pagination *ngIf="!notFound && totalCount > itemsPerPage" [boundaryLinks]="true" [totalItems]="totalCount"
        [itemsPerPage]="itemsPerPage" [(ngModel)]="currentPage" (pageChanged)="pageChanged($event)"
        previousText="&lsaquo;" nextText="&rsaquo;" [maxSize]="10" firstText="&laquo;" lastText="&raquo;">
    </pagination>
</div>

<ng-template #template>
    <div class="modal-header">
        <div class="d-flex justify-content-center w-100">
            <h2 class="m-0 p-0" *ngIf="addNew">Add New Sport</h2>
            <h2 class="m-0 p-0" *ngIf="edit">Edit Sport</h2>
        </div>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide();cancelModel();">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body ">
        <form [formGroup]="sportForm">
            <div class="form-group">
                <label for="sportName" class="form-label">Name:<font style="color: red;">*</font></label>
                <input type="text" class="form-control" placeholder="Enter sport name" id="sportName" formControlName="name">
                <div *ngIf="submitted && f.name.errors" class="alert alert-danger">
                    <span *ngIf="f.name.errors.required">
                        Name is required
                    </span>
                </div>
                <div *ngIf="f.name.errors && f.name.errors.maxlength" class="alert alert-danger">
                    <span>Name should be less than 36 character.</span>
                </div>
            </div>
            <div class="d-flex">
                <div class="form-group mb-0 me-3">
                    <label for="sportIcon" class="form-label">Icon:<font style="color: red;">*</font></label>
                    <input type="file" style="width: 117px;"  class="form-control" placeholder="Upload sport icon" id="sportIcon" (change)="onUploadIcon($event)" accept="image/png, image/jpeg, image/jpg">
                </div>
                <img *ngIf="previewPath" id="iconPreview" [src]="previewPath" alt="icon preview" style="height: 65px;width: 65px;" class="m-0 rounded">
            </div>
            <div *ngIf="submitted && !previewPath" class="alert alert-danger">
                <span>Sport image is required</span>
              </div>
        </form>
    </div>
    <div class="modal-footer modal-btn">
        <button class="btn btn-cancel ms-2"  (click)="modalRef.hide();cancelModel();">Cancel</button>
        <button class="btn btn-green ms-2" *ngIf="addNew" (click)="createNewSport()">Add</button>
        <button class="btn btn-green ms-2" *ngIf="edit" (click)="editSport()">Update</button>
    </div>
</ng-template>
<!-- delete modal -->
<ng-template #delModel>
    <div class="modal-header">
        <div class="d-flex justify-content-center w-100">
            <h2 class="m-0 p-0">Delete Sport</h2>
        </div>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body text-center delete-modal">
        <img src="../../../assets/images/delete-img.svg">
        <p>Are you sure you want to <br>delete this sport?</p>
    </div>
    <div class="modal-footer modal-btn">
        <button class="btn btn-cancel" (click)="modalRef.hide()">Cancel</button>
        <button class="btn btn-green ms-2" (click)="confirmDel()">Confirm</button>
    </div>
</ng-template>
<!-- enlarge image -->
<ng-template #enLgImg>
    <div class="modal-header">
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" style="height: 495px;" >
        <img [hidden]="loading" (load)="onLoad()" class="m-0 cover w-100 h-100 rounded" src="{{ elgSportIcon }}" />
    </div>
</ng-template>
