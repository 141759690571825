<!-- <app-layout></app-layout> -->

<div class="content">
    <div class="justify-content-between m-0 row">
        <div class="align-items-center border rounded col-auto d-flex mb-2">
            <span>Total: <strong>{{totalCount || 0}}</strong></span>
        </div>
        <div class="col-auto mb-2 news-header" (click)="openAddTrainerModel(template)">
            <button class="btn btn-create">
                <img src="../../../assets/ludus_icon/black/plush-icon.svg" alt="">
                Add Trainer
            </button>
        </div>
    </div>
    <div class="table-responsive mb-3">
        <table class="table border table-hover">
            <thead>
                <tr>
                    <th scope="col" style="min-width: 4rem;">S No.</th>
                    <th scope="col" style="min-width: 6rem;">Name</th>
                    <th scope="col">Description</th>
                    <th scope="col" style="min-width: 8rem; text-align: center;">Profile Image</th>
                    <th scope="col" style="min-width: 6rem;">Sport</th>
                    <th scope="col" style="min-width: 6rem;">Action</th>
                </tr>
            </thead>
            <tbody>
                    <tr *ngFor="let item of trainerList; let i = index">
                        <td>{{((currentPage-1)*itemsPerPage)+(i+1)}}.</td>
                        <td><span class="text-short" style="max-width: 10rem;">{{item.name?item.name :'-'}}</span></td>
                        <td><span class="text-short" style="max-width: 15rem;">{{item.disc || '-'}}</span></td>
                        <td class="text-center">
                            <img class="rounded" *ngIf="item.profileImg" (click)="enlargeImg(enLgImg,item.profileImg)" src="{{item.profileImg}}" width="40px" height="40px">
                        </td>
                        <td><span class="text-short" style="max-width: 10rem;">{{getSportName(item.sportId)}}</span></td>
                        <td >
                            <span style="cursor: pointer;margin-right: 10px;" (click)="openEditTrainer(template,item)">
                                <img src="../../../../../assets/icons/edit-icon.svg" />
                            </span>
                            <span  style="cursor: pointer;" (click)="openDeleteTrainer(delModel, item._id)">
                                <img src="../../../assets/icons/delete-icon.svg" />
                            </span>
                        </td>
                    </tr>
            </tbody>
        </table>
    </div>
    <div class="d-flex justify-content-center" *ngIf="notFound">
        <img src="../../../assets/images/not-found.svg"  alt="">
    </div>

    <pagination *ngIf="!notFound && totalCount > itemsPerPage" [boundaryLinks]="true" [totalItems]="totalCount"
        [itemsPerPage]="itemsPerPage" [(ngModel)]="currentPage" (pageChanged)="pageChanged($event)"
        previousText="&lsaquo;" nextText="&rsaquo;" [maxSize]="10" firstText="&laquo;" lastText="&raquo;">
    </pagination>
</div>

<!-- edit and update modal -->
<ng-template #template>
    <div class="modal-header">
        <div class="d-flex justify-content-center w-100">
            <h2 class="m-0 p-0" *ngIf="addNew">Add New Trainer</h2>
            <h2 class="m-0 p-0" *ngIf="edit">Update Trainer Details</h2>
        </div>
        <button type="button"  class="close pull-right" aria-label="Close" (click)="modalRef.hide();cancelModel();">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body ">
        <form [formGroup]="trainerForm">
            <div class="form-group">
                <label class="form-label" for="trainerName">Name:<font style="color: red;">*</font></label>
                <input type="text" class="form-control" placeholder="Enter trainer name" id="trainerName" formControlName="name">
                <div *ngIf="submitted && f.name.errors" class="alert alert-danger">
                    <span *ngIf="f.name.errors.required">
                        Name is required
                    </span>
                </div>
                <div *ngIf="f.name.errors && f.name.errors.maxlength" class="alert alert-danger">
                    <span>Name should be less than 36 character.</span>
                </div>
            </div>
            <div class="form-group">
                <label class="form-label" for="trainerSport">Sport:<font style="color: red;">*</font></label>
                <div class="form-control text-capitalize mb-1" id="trainerSport">
                    <mat-select formControlName="sportId"  placeholder="Select sport">
                        <mat-option *ngFor="let spObj of sportList" [value]="spObj._id">
                            <img width="30px" height="30px" [src]="spObj.icon" class="rounded">
                            {{spObj.name }}
                        </mat-option>
                    </mat-select>
                </div>
                <div *ngIf="submitted && f.sportId.errors" class="alert alert-danger">
                    <span *ngIf="f.sportId.errors.required">
                        Sport is required
                    </span>
                </div>
            </div>
            <div class="form-group">
                <label class="form-label" for="trainerDesc">Description:<font style="color: red;">*</font></label>
                <textarea id="trainerDesc" class="form-control" placeholder="Enter trainer description" cols="30" rows="2" formControlName="disc"></textarea>
                <div *ngIf="submitted && f.disc.errors" class="alert alert-danger">
                    <span *ngIf="f.disc.errors.required">
                        Description is required
                    </span>
                </div>
                <div *ngIf="f.disc.errors && f.disc.errors.maxlength" class="alert alert-danger">
                    <span>Description should be less than 255 character.</span>
                </div>
            </div>
            <div class="d-flex">
                <div class="form-group mb-0 me-3">
                    <label class="form-label" for="trainerDesc">Profile Image:<font style="color: red;">*</font> <span class="text-muted">(Recommend Size: 80 X 80)</span></label>
                    <input type="file" style="width: 117px;" class="form-control" (change)="onFileChange($event)"  accept="image/png, image/jpeg, image/jpg">
                </div>
                <img *ngIf="previewImgSrc" [src]="previewImgSrc" style="height: 65px;width: 65px;" class="rounded m-0">
            </div>
            <div *ngIf="submitted && !previewImgSrc" class="alert alert-danger">
                <span>Profile image is required</span>
            </div>
        </form>
    </div>
    <div class="modal-footer modal-btn">
        <button class="btn btn-cancel ms-2" (click)="modalRef.hide();cancelModel();">Cancel</button>
        <button class="btn btn-green ms-2" *ngIf="addNew" (click)="createNewTrainer()">Add</button>
        <button class="btn btn-green ms-2" *ngIf="edit" (click)="editTrainer()">Update</button>
    </div>
</ng-template>
<!-- delete modal -->
<ng-template #delModel>
    <div class="modal-header">
        <div class="d-flex justify-content-center w-100">
            <h2 class="m-0 p-0">Delete Trainer</h2>
        </div>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body text-center delete-modal">
        <img src="../../../assets/images/delete-img.svg" alt="">
        <p>Are you sure you want to <br>delete this trainer?</p>
    </div>
    <div class="modal-footer modal-btn">
        <button class="btn btn-cancel" (click)="modalRef.hide()">Cancel</button>
        <button class="btn btn-green ms-2" (click)="confirmDel()">Confirm</button>
    </div>
</ng-template>
<!-- enlarge image -->
<ng-template #enLgImg>
    <div class="modal-header">
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" style="height: 495px;">
        <img [hidden]="loading" (load)="onLoad()" class="m-0 cover w-100 h-100 rounded" src="{{ elgProfileImg }}" />
    </div>
</ng-template>
