import { Component, OnInit, TemplateRef } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { BsModalRef, BsModalService, ModalOptions } from "ngx-bootstrap/modal";
import { PageChangedEvent } from "ngx-bootstrap/pagination";
import { ToastrService } from "ngx-toastr";
import { SportService } from "src/app/services/sport.service";
import { TrainerService } from "src/app/services/trainer.service";
import { SharedService } from "src/app/shared/services/shared.service";

@Component({
  selector: "app-trainer",
  templateUrl: "./trainer.component.html",
  styleUrls: ["./trainer.component.scss"],
})
export class TrainerComponent implements OnInit {
  trainerList: any = [];
  sportList: any;
  previewImgSrc: string = "";
  uploadedImg: File = null;
  uploadedFileType: any = ["image/png", "image/jpeg", "image/jpg"];

  notFound: boolean = false;
  totalCount: number = 0;
  currentPage: number = 1;
  itemsPerPage: number = 10;
  modalRef: BsModalRef;
  uploadedIcon: File = null;
  edit: boolean = false;
  editTrainerData: any;
  editSportId: string = "";
  delTrainerId: string = "";
  elgProfileImg: string = "";
  addNew: boolean = false;
  prevFile: any;
  submitted: boolean = false;

  trainerForm = new FormGroup({
    name: new FormControl("", [Validators.required, Validators.maxLength(36)]),
    sportId: new FormControl("", [Validators.required]),
    disc: new FormControl("", [Validators.required, Validators.maxLength(255)]),
    profileImg: new FormControl("", [Validators.required]),
  });
  imgUpload: boolean = false;

  get f() {
    return this.trainerForm.controls;
  }
  constructor(private sportService: SportService, private modalService: BsModalService, private toastrService: ToastrService, private trainerService: TrainerService, private sharedService: SharedService) {
    this.sportService.getAllSport().subscribe((res: any) => {
      if (res.payload.length == 0 || res.error) {
        this.notFound = true;
      } else {
        this.sportList = res.payload.data;
      }
    });
  }

  ngOnInit(): void {
    this.getTrainersList(this.currentPage, this.itemsPerPage);
  }

  pageChanged(event: PageChangedEvent): void {
    this.getTrainersList(event.page, event.itemsPerPage);
  }
  getTrainersList(page: number, pageLimit: number) {
    this.trainerService.getTrainers(page, pageLimit).subscribe(
      (res: any) => {
        this.trainerList = res.payload.data;
        this.totalCount = res.payload.totalCount;
        if (!this.trainerList.length) this.notFound = true;
      },
      (error) => {
        this.notFound = true;
      }
    );
  }

  openAddTrainerModel(template: TemplateRef<any>) {
    this.reset();
    this.addNew = true;
    this.modalRef = this.modalService.show(template, { ignoreBackdropClick: true, backdrop: "static", keyboard: false });
  }
  createNewTrainer(): void {
    this.submitted = true;
    if (this.trainerForm.invalid) {
      this.toastrService.info("Please fill required fields");
      return;
    }
    this.trainerService.createTrainer(this.trainerForm.value).subscribe(
      (res: any) => {
        if (!this.trainerList.length) this.trainerList.push(res.payload);
        else if (this.trainerList.length <= this.itemsPerPage) {
          this.trainerList.splice(this.itemsPerPage - 1, 1);
          this.trainerList.splice(0, 0, res.payload);
        }
        this.totalCount += 1;
        this.toastrService.success("Trainer is created successfully");
        this.modalRef.hide();
      },
      (error) => {
        this.toastrService.error(error.error.error.message);
      }
    );
  }

  openDeleteTrainer(template: TemplateRef<any>, trainerId: string): void {
    this.delTrainerId = trainerId;
    this.modalRef = this.modalService.show(template, { ignoreBackdropClick: true, backdrop: "static", keyboard: false });
  }
  confirmDel(): void {
    if (!this.delTrainerId) {
      this.toastrService.info("Select at least one trainer");
      return;
    }
    this.trainerService.deleteSport(this.delTrainerId).subscribe(
      (res: any) => {
        // let index = this.trainerList.findIndex(
        //   (ele) => ele._id == this.delTrainerId
        // );
        // this.trainerList.splice(index, 1);
        // if (!this.trainerList.length) this.notFound = true;
        // this.totalCount -= 1;
        this.getTrainersList(this.currentPage, this.itemsPerPage);
        this.toastrService.success("Trainer is deleted successfully");
        this.modalRef.hide();
      },
      (error) => {
        this.toastrService.error(error.error.error.message);
      }
    );
  }

  openEditTrainer(template: TemplateRef<any>, trainer: any) {
    this.reset();
    this.editTrainerData = trainer;
    this.edit = true;
    this.trainerForm.patchValue({
      name: trainer.name,
      sportId: trainer.sportId,
      disc: trainer.disc,
      profileImg: trainer.profileImg,
    });
    this.previewImgSrc = trainer.profileImg;
    // this.f.profileImg.clearValidators();
    this.modalRef = this.modalService.show(template, { ignoreBackdropClick: true, backdrop: "static", keyboard: false });
  }
  editTrainer(): void {
    this.submitted = true;
    let updatedValue = {};
    if (this.editTrainerData.name !== this.f.name.value.trim()) updatedValue["name"] = this.f.name.value.trim();
    if (this.editTrainerData.sportId !== this.f.sportId.value.trim()) updatedValue["sportId"] = this.f.sportId.value.trim();
    if (this.editTrainerData.disc !== this.f.disc.value.trim()) updatedValue["disc"] = this.f.disc.value.trim();
    if (this.editTrainerData.profileImg !== this.previewImgSrc) {
      updatedValue["profileImg"] = this.previewImgSrc;
      updatedValue["prevProfileImg"] = this.editTrainerData.profileImg;
    }
    if (!Object.keys(updatedValue).length) {
      this.toastrService.info("Update at least one field");
      return;
    }
    this.trainerService.updateTrainer(updatedValue, this.editTrainerData._id).subscribe(
      (res: any) => {
        let index = this.trainerList.findIndex((ele) => ele._id == this.editTrainerData._id);
        this.trainerList.splice(index, 1, res.payload);
        this.toastrService.success("Trainer is updated successfully");
        this.modalRef.hide();
      },
      (error) => {
        this.toastrService.error(error.error.error.message);
      }
    );
  }
  enlargeImg(popUp: TemplateRef<any>, proUrlImg: string): void {
    const config: ModalOptions = { class: "modal-lg", ignoreBackdropClick: true, backdrop: "static", keyboard: false };
    this.modalRef = this.modalService.show(popUp, config);
    this.elgProfileImg = proUrlImg;
  }

  onFileChange(event: any) {
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      if (!this.uploadedFileType.includes(file.type)) {
        this.toastrService.info("You can select only image file");
        return;
      }
      this.sharedService.uploadFile(file, this.previewImgSrc).subscribe(
        (res: any) => {
          this.previewImgSrc = res.payload.uploadedUrl;
          this.trainerForm.patchValue({ profileImg: this.previewImgSrc });
          this.imgUpload = true;
        },
        (err: any) => {
          this.toastrService.error(err.error.error.message);
        }
      );
    }
  }
  reset(): void {
    this.trainerForm.reset({
      name: "",
      sportId: "",
      disc: "",
      profileImg: "",
    });
    this.addNew = false;
    this.edit = false;
    this.previewImgSrc = "";
    this.submitted = false;
    this.editTrainerData = null;
  }
  loading: boolean = true;
  onLoad() {
    this.loading = false;
  }
  getSportName(sportId: string) {
    let index = this.sportList.findIndex((ele) => ele._id.toString() == sportId);
    return this.sportList[index]["name"];
  }
  cancelModel() {
    if (this.previewImgSrc && this.imgUpload) {
      this.sharedService.deleteUploadedFile(this.previewImgSrc).subscribe(
        (res: any) => (this.previewImgSrc = ""),
        (err: any) => (this.previewImgSrc = "")
      );
    }
  }
}
