import { BrowserModule } from "@angular/platform-browser";
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";

import { ImageCropperModule } from "ngx-image-cropper";
import { CommonModule } from "@angular/common";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { ToastrModule } from "ngx-toastr";
import { NgHttpLoaderModule } from "ng-http-loader";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatIconModule } from "@angular/material/icon";
import { ModalModule } from "ngx-bootstrap/modal";
import { CustomFormsModule } from "ng2-validation";

import { NgxSliderModule } from "@angular-slider/ngx-slider";

import { AppRoutingModule } from "./app-routing.module";
import { AuthModule } from "./auth/auth.module";
import { SharedModule } from "./shared/shared.module";

import { AppComponent } from "./app.component";
import { AuthInterceptor } from "./_helpers/auth.interceptor";
import { AuthService } from "./auth/services/auth.service";
import { SharedService } from "./shared/services/shared.service";
import { ViewsComponent } from "./views/views.component";
import { AuthGuard } from "./_helpers/auth.guard.service";
import { SessionService } from "./shared/services/session.service";
import { environment } from "src/environments/environment";
import { MatSelectModule } from "@angular/material/select";
import { CKEditorModule } from "@ckeditor/ckeditor5-angular";

import { DashboardService } from "./dashboard/services/dashboard.service";
import { SafeHtmlPipe } from "./shared/pipes/safe-html.pipe";
import { UsersComponent } from "./container/users/users.component";
import { PaginationModule } from "ngx-bootstrap/pagination";
import { SportComponent } from "./container/sport/sport.component";
import { TrainerComponent } from "./container/trainer/trainer.component";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { PasswordChangeComponent } from "./container/password-change/password-change.component";
@NgModule({
    declarations: [
        AppComponent,
        ViewsComponent,
        UsersComponent,
        SportComponent,
        TrainerComponent,
        PasswordChangeComponent,
    ],
    imports: [
        CommonModule,
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        AuthModule,
        SharedModule,
        ImageCropperModule,
        HttpClientModule,
        NgHttpLoaderModule.forRoot(),
        ToastrModule.forRoot({
            preventDuplicates: true,
        }),
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatIconModule,
        PaginationModule.forRoot(),
        ModalModule.forRoot(),
        CustomFormsModule,
        NgxSliderModule,
        MatSelectModule,
        MatSlideToggleModule,
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        AuthGuard,
        AuthService,
        SharedService,
        DashboardService,
        SessionService,
    ],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
