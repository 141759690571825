<div class="sidebar theme-gradient">
  <div class="m-0 row ">
    <div class="col-12 d-flex mb-3 mt-3 p-0" style="height: 95px;">
      <div class="d-flex justify-content-center w-100 ">
        <img src="../../../assets/ludus_icon/sidebar-logo.svg" width="100" alt="logo">
      </div>
    </div>

    <div class="col-12 header-links m-0 p-0">

      <a routerLink="/users" routerLinkActive="active">
        <span class="sidebar-icon">
          <img class="black-icon" src="../../../assets/ludus_icon/black/user.svg" alt="">
          <img class="white-icon" src="../../../assets/ludus_icon/white/user.svg" alt="" height="24" width="24">
        </span>Users</a>

      <a routerLink="/sports" routerLinkActive="active">
        <span class="sidebar-icon">
          <img class="black-icon" src="../../../assets/ludus_icon/black/target.svg" alt="">
          <img class="white-icon" src="../../../assets/ludus_icon/white/target.svg" alt="" height="24" width="24">
        </span>Sports</a>

      <a routerLink="/trainers" routerLinkActive="active">
        <span class="sidebar-icon">
          <img class="black-icon" src="../../../assets/ludus_icon/black/trainer.svg" alt="">
          <img class="white-icon" src="../../../assets/ludus_icon/white/trainer.svg" alt="" height="24" width="24">
        </span>Trainers</a>

      <a routerLink="/courses" routerLinkActive="active">
        <span class="sidebar-icon">
          <img class="black-icon" src="../../../assets/ludus_icon/black/course.svg" alt="">
          <img class="white-icon" src="../../../assets/ludus_icon/white/course.svg" alt="" height="24" width="24">
        </span>Courses & Modules</a>

      <!-- <a routerLink="/analytics" routerLinkActive="active">
        <span class="sidebar-icon">
          <img class="black-icon" src="../../../assets/little/dashboard.svg" alt="">
          <img class="white-icon" src="../../../assets/little white/dashboard.svg" alt="" height="24" width="24">
        </span>Analytics</a> -->

      <a routerLink="/password-change" routerLinkActive="active">
        <span class="sidebar-icon">
          <img class="black-icon" src="../../../assets/ludus_icon/black/settings.svg" alt="">
          <img class="white-icon" src="../../../assets/ludus_icon/white/settings.svg" alt="" height="24" width="24">
        </span>Change Password</a>

        <a routerLinkActive="active" (click)="openModal(template)" id="logout">
        <span class="sidebar-icon">
          <img class="black-icon" src="../../../assets/little/Log Out Black.svg" alt="" height="24" width="24">
          <img class="white-icon" src="../../../assets/little/Log Out.svg" alt="" height="24" width="24">
        </span>Logout</a>
    </div>
  </div>
</div>

<!-- Logout confirm -->
<ng-template #template>
  <div class="modal-header" style="margin: 1rem 0rem 0rem 1rem;">
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body text-center delete-modal align-items-center">
    <img src="../../../assets/images/create-successfull-img.svg" alt="">
    <p>Are you sure you <br>want to logout?</p>
  </div>
  <div class="modal-footer modal-btn">
      <button class="btn btn-cancel ms-2" (click)="cancel()">No</button>
      <button class="btn btn-green ms-2" (click)="confirm()">Yes</button>
  </div>
</ng-template>