import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { ViewsComponent } from "./views/views.component";
import { AuthGuard } from "./_helpers/auth.guard.service";
import { UsersComponent } from "./container/users/users.component";
import { SportComponent } from "./container/sport/sport.component";
import { TrainerComponent } from "./container/trainer/trainer.component";
import { PasswordChangeComponent } from "./container/password-change/password-change.component";

const routes: Routes = [
  {
    path: "auth",
    loadChildren: () => import("./auth/auth.module").then((m) => m.AuthModule),
  },
  {
    path: "",
    component: ViewsComponent,
    canActivate: [AuthGuard],
    children: [
      { path: "", pathMatch: "full", redirectTo: "users" },
      { path: "users", component: UsersComponent },
      { path: "sports", component: SportComponent },
      { path: "trainers", component: TrainerComponent },
      { path: "password-change", component: PasswordChangeComponent },
      {
        path: "courses",
        loadChildren: () => import("./container/course-classes/course-classes.module").then((m) => m.CourseClassesModule),
      },
      {
        path: "analytics",
        loadChildren: () => import("./container/analytics/analytics.module").then((m) => m.AnalyticsModule),
      },
      {
        path: "dashboard",
        loadChildren: () => import("./dashboard/dashboard.module").then((m) => m.DashboardModule),
      },
      {
        path: "analytics-dashboard",
        loadChildren: () => import("../app/dashboard/dashboard.module").then((m) => m.DashboardModule),
      },
    ],
  },
  { path: "", redirectTo: "auth", pathMatch: "full" },
  // { path: '**', redirectTo: '', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: "legacy" })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
