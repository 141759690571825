import { Component, OnInit, TemplateRef } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { BsModalService } from "ngx-bootstrap/modal";
import { HeaderService } from "../services/header.service";
import { AuthService } from "src/app/auth/services/auth.service";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {

  manageCaregiversHeading: boolean = false;
  manageClientHeading: boolean = false;
  manageServicesHeading: boolean = false;
  manageTutorialHeading: boolean = false;
  manageConvenienceHeading: boolean = false;
  manageDiseasesHeading: boolean = false;
  manageTutorialCategory: boolean = false;



  welcomeUserMsg: boolean = false;
  hospitalHeading: boolean = false;
  loginHeading: boolean = false;
  usageHeading: boolean = false;
  supportHeading: boolean = false;
  feedbackHeading: boolean = false;
  manageHeading: boolean = false;
  aboutHeading: boolean = false;
  dashboardHeading: boolean = false;
  changePasswordHeading: boolean = false;
  pushNotificationHeading: boolean = false;
  addUserHeading: boolean = false;
  privacyPolicyHeading: boolean = false;
  surveyAnswersHeading: boolean = false;





  importedEventsHeading: boolean = false;
  subEventsHeading: boolean = false;
  zoneHeading: boolean = false;
  subZoneHeading: boolean = false;
  venueHeading: boolean = false;
  interestHeading: boolean = false;
  pointHeading: boolean = false;
  placeHeading: boolean = false;
  merchandiseHeading: boolean = false;
  sectionHeading: boolean = false;
  categoryHeading: boolean = false;
  pointcategoryHeading: boolean = false;
  homeScreenPartnersHeading: boolean = false;
  eventsponsorsHeading: boolean = false;
  notificationHeading: boolean = false;
  protocolHeading: boolean = false;
  seasonsHeading: boolean = false;
  chatListHeading: boolean = false;
  chatDetailsHeading: boolean = false;
  requestHeading: boolean = false;
  settingHeading: boolean = false;
  restaurantsHeading: boolean = false;
  requestsHeading: boolean = false;
  customerHeading: boolean = false;
  zoneRequestHeading: boolean = false;
  zoneChatHeading: boolean = false;
  zoneCallHeading: boolean = false;
  zoneReportingHeading: boolean = false;
  EventRequestHeading: boolean = false;
  BannerHeading: boolean = false;
  CuisineHeading: boolean = false;
  PopupHeading: boolean = false;
  PdfHeading: boolean = false;
  TreasureHeading: boolean = false;
  CouponHeading: boolean = false;
  ZoneCoordinatesHeading: boolean = false;
  SubZoneCoordinatesHeading: boolean = false;
  emailSupportHeading: boolean = false;

  default: boolean;
  lang: string;

  private returnUrl: any;

  profilePic: String = "";
  modalRef: any;

  constructor(
    private _header: HeaderService,
    public router: Router,
    private modalService: BsModalService,
    private route: ActivatedRoute,
    private authService: AuthService,
    private toAsterService: ToastrService
  ) {





    this._header.manageCaregiversHeading.subscribe((res) => {
      this.manageCaregiversHeading = res;
    });


    this._header.manageClientHeading.subscribe((res) => {
      this.manageClientHeading = res;
    });


    this._header.manageServicesHeading.subscribe((res) => {
      this.manageServicesHeading = res;
    });

    this._header.manageTutorialHeading.subscribe((res) => {
      this.manageTutorialHeading = res;
    });

    this._header.manageTutorialCategory.subscribe((res) => {
      this.manageTutorialCategory = res;
    });


    this._header.manageConvenienceHeading.subscribe((res) => {
      this.manageConvenienceHeading = res;
    });


    this._header.manageDiseasesHeading.subscribe((res) => {
      this.manageDiseasesHeading = res;
    });





    this._header.welcomeUserMsg.subscribe((res) => {
      this.welcomeUserMsg = res;
    });


    this._header.dashboardHeading.subscribe((res) => {
      this.dashboardHeading = res;
    });


    this._header.changePasswordHeading.subscribe((res) => {
      this.changePasswordHeading = res;
    });










  }

  userName: String;
  firstName: String;
  lastName: String;
  allDate: any;


  currentUser: any;
  ngOnInit(): void {
    // let user = JSON.parse(localStorage.getItem("currentUser"));
    this.currentUser = this.authService.currentUserValue;
    this.default = true;
    this.lang = "English";
    this.userName = "Admin";
    this.currentUser = this.authService.currentUserValue;
    this.profilePic = "../../../assets/icons/user-img.svg";

    this.allDate = this.authService.currentUserValue;
    this.userName = this.allDate?.firstName?.toUpperCase();

  }

  logout() {
    this.modalRef.hide();

    this.authService.logout({ email: this.currentUser.email, password: this.currentUser.password }).subscribe((res: any) => {
      console.log(res.status);
      if (res.status == 200) {
        localStorage.removeItem("currentUser");
      }
      // else{
      //   this.toAsterService.error(res.error.message);
      // }

    });
    this.router.navigateByUrl("/auth/login");
  }

  // modal lougout confirmation
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }
  confirm() {
    this.logout();
  }
  cancel() {
    this.modalRef.hide();
  }
}
