<div id="auth-width">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-6 theme-gradient">
        <div class="login-banner">
          <img src="../../../../assets/ludus_icon/home-logo.svg" alt="login-banner" class="img-fluid">
        </div>
      </div>
      <div class="col-md-6">
        <div class="login-field">
          <div class="login-box">
            <h3>Sign In</h3>
            <!-- <p class="mb/ss-3">Promoting skilled trades and technologies in Ontario since 1989.</p> -->
            <form [formGroup]="loginForm" (submit)="login()"  autocomplete="off">
              <div class="form-group">
                <label for="email">Email Address *</label>
                <div class="align-items-center d-flex">
                  <span class="input-icon">
                    <img src="../../../../assets/icons/email.svg">
                  </span>
                  <input type="email" class="form-control" placeholder="Email" id="email" formControlName="email"  autocomplete="off">
                </div>
                <div *ngIf="submitted && f.email.errors" class="alert alert-danger">
                  <span *ngIf="f.email.errors.required">
                    Email Required
                  </span>
                  <span *ngIf="f.email.errors.email">
                    Invalid Email
                  </span>
                </div>
              </div>
              <div class="form-group mb-0">
                <label for="email">Password *</label>
                <div class="align-items-center d-flex">
                    <span class="input-icon">
                      <img src="../../../../assets/icons/password.svg">
                    </span>
                  <input [type]="togglePass ? 'password' : 'text'" class="form-control" placeholder="Password" id="password" formControlName="password">
                    <span class="input-icon-suffix">
                      <img *ngIf="togglePass"  (click)="togglePass= !togglePass" src="../../../../assets/ludus_icon/view-icon.svg" >
                      <img *ngIf="!togglePass"  (click)="togglePass= !togglePass" src="../../../../assets/ludus_icon/view-icon-fill.svg" >
                    </span>
                </div>
                <div *ngIf="submitted && f.password.errors" class="alert alert-danger">
                    <span *ngIf="f.password.errors.required">
                      Password Required
                    </span>
                    <span *ngIf="f.password.errors.minlength">
                      Password must be of six or more characters
                    </span>
                </div>

            </div>
            <div class="mt-1 text-end text-right">
              <span class="forgot-pass" routerLink="/auth/forgot-password">Forgot Password?</span>
            </div>
             
              <button type="submit" class="btn btn-primary">Sign In</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>