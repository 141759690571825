import { Component, OnInit } from "@angular/core";

@Component({
    selector: "app-view",
    template: `<app-layout></app-layout><router-outlet></router-outlet>`,
})
export class ViewsComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
