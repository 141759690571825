<div id="forgot-password">
    <div class="container-fluid">
        <div class="row">
            <div class="col-auto logo  position-absolute" style="z-index: 1;">
                <img src="../../../../assets/ludus_icon/home-logo.svg">
            </div>
            <div class="col-12 theme-gradient">
                <div class="login-field">
                    <div class="login-box bg-white">
                        <h3>Reset your Password</h3>
                        <p class="mb-3">Enter the email address associated with your account and we will send you the
                            new
                            password.</p>
                        <form [formGroup]="resetForm" (submit)="resetPassword()" autocomplete="off">
                            <div class="form-group mb-2">
                                <label for="email">Email Address</label>
                                <div class="align-items-center d-flex">
                                    <span class="input-icon">
                                        <img src="../../../../assets/icons/email.svg">
                                    </span>
                                    <input type="email" autocomplete="off" class="form-control" placeholder="Email" id="email" formControlName="email">
                                </div>
                                <div *ngIf="submitted && f.email.errors" class="alert alert-danger">
                                    <span *ngIf="f.email.errors.required">
                                        Email is required.
                                    </span>
                                    <span *ngIf="f.email.errors.email">
                                        Invalid email.
                                    </span>
                                </div>
                            </div>
                            <div>
                                <span class="back-signin" routerLink="/auth/login">
                                    <img src="../../../../assets/icons/back-black.svg" style="height: 10px;">
                                    Back to Sign In
                                </span>
                            </div>
                            <button type="submit" class="btn btn-primary">Reset Password</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<!-- Forget Password -->
<ng-template #template>
    <div class="modal-header">
      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body text-center delete-modal">
        <img src="../../../../assets/images/create-successfull-img.svg" alt="">
        <p>Password sent to your<br />email address</p>
    </div>
    <div class="modal-footer modal-btn">
        <button class="btn btn-cancel btn-primary" (click)="OkPress()">OK</button>
    </div>
</ng-template>