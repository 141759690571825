import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { AuthService } from "../../services/auth.service";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-forgot-password",
  templateUrl: "./forgot-password.component.html",
  styleUrls: ["./forgot-password.component.scss"],
})
export class ForgotPasswordComponent implements OnInit {
  submitted: boolean = false;
  modalRef: BsModalRef;

  @ViewChild("template") template: TemplateRef<any>;
  constructor(
    private authService: AuthService,
    private router: Router,
    private modalService: BsModalService,
    private toastr: ToastrService
  ) {}

  resetForm = new FormGroup({
    email: new FormControl("", [Validators.required, Validators.email]),
  });

  ngOnInit(): void {}

  get f() {
    return this.resetForm.controls;
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  sendEmailToLogin: any;

  resetPassword() {
    this.submitted = true;
    if (this.resetForm.invalid || this.resetForm.pristine) return;
    this.submitted = false;

    this.authService
      .forgetPassword({
        email: this.resetForm.value.email.toLowerCase(),
      })
      .subscribe(
        (res: any) => this.openModal(this.template),
        (error: any) => this.toastr.error(error.error.error.message)
      );
  }

  OkPress() {
    this.modalRef.hide();
    this.router.navigateByUrl("/login");
  }
}
