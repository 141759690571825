import { Component, OnDestroy, OnInit } from '@angular/core';
import { HeaderService } from 'src/app/shared/services/header.service';
import { DashboardService } from '../services/dashboard.service';
import { AuthService } from 'src/app/auth/services/auth.service';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit, OnDestroy {
  public canvas: any;
  public ctx: any;
  public chart: any;
  dashboardData: any = [];
  dashboardAllData:any;
  graphData: any;
  months: number = 6;
  currentUser:any;
  analyticsData:any;
  finalCount:any;
  clientCounts:number=0;
  careGiverCounts:number=0;
  bookingCount:number=0;
  incomeCount:number=0;

  constructor(
    private _header: HeaderService, 
    private dashboardService: DashboardService,
    private authService: AuthService,
    ) { }

  ngOnInit(): void {
    this._header.welcomeUserMsg.next(true);
    this.currentUser = this.authService.currentUserValue;

    this.dashboardService.getAnalytics({}).subscribe((res:any) => {
      if(res.status == 200){
         this.analyticsData = res.output;

        let clientCountsStop = setInterval(()=> {
            this.clientCounts++
            if(this.clientCounts == this.analyticsData?.numberOfClient){
              clearInterval(clientCountsStop);
            }
          })

          let caregiverCountsStop = setInterval(()=> {
            this.careGiverCounts++
            if(this.careGiverCounts == this.analyticsData?.numberOfCaregiver){
              clearInterval(caregiverCountsStop);
            }
          })

          let bookingCountsStop = setInterval(()=> {
            this.bookingCount++
            if(this.bookingCount == this.analyticsData?.numberOfAppointments){
              clearInterval(bookingCountsStop);
            }
          })

          let income = parseInt(this.analyticsData?.numberOfIncome);

          let incomeCountsStop = setInterval(()=> {
            this.incomeCount = this.incomeCount+ 50;
            if(this.incomeCount > income){
              clearInterval(incomeCountsStop);
              this.incomeCount = this.analyticsData?.numberOfIncome;
            }
          })

        //  let income = parseInt(this.analyticsData?.numberOfIncome);
        //  this.animateCounter((<HTMLInputElement>document.getElementById('client')), 0, this.analyticsData?.numberOfClient-2, 1000);
        //  this.animateCounter((<HTMLInputElement>document.getElementById('caregiver')), 0, this.analyticsData?.numberOfCaregiver-2, 1000);             
        //  this.animateCounter((<HTMLInputElement>document.getElementById('booking')), 0, this.analyticsData?.numberOfAppointments-5, 1000);             
        //  this.animateCounter((<HTMLInputElement>document.getElementById('income')), 0, income, 1000);             
      }
    })
    
  }
  

  ngOnDestroy() {
    this._header.welcomeUserMsg.next(false);
  }


  animateCounter(obj, initVal, lastVal, duration) {
    let startTime = null;

    //get the current timestamp and assign it to the currentTime variable
    let currentTime = Date.now();

    //pass the current timestamp to the step function
    const step = (currentTime ) => {

    //if the start time is null, assign the current time to startTime
    if (!startTime) {
      startTime = currentTime ;
    }

    //calculate the value to be used in calculating the number to be displayed
    const progress = Math.min((currentTime - startTime)/ duration, 20);

    //calculate what to be displayed using the value gotten above
    obj.innerHTML = Math.floor(progress * (lastVal - initVal) + initVal);

    //checking to make sure the counter does not exceed the last value (lastVal)
    if (progress < 1) {
        window.requestAnimationFrame(step);
    } else {
          window.cancelAnimationFrame(window.requestAnimationFrame(step));
        }
    };
    //start animating
        window.requestAnimationFrame(step);
 }
}
