<div class="header">
    <div class="left-sec">
        <ng-container *ngIf="welcomeUserMsg">
            <h1>Dashboard</h1>
            <!-- <p>Hello {{userName}}, Welcome Back!</p> -->
        </ng-container>
        <h1 *ngIf="manageCaregiversHeading">Manage Caregivers / Companion</h1>
        <h1 *ngIf="manageClientHeading">Manage Clients</h1>
        <h1 *ngIf="manageServicesHeading">Manage Services</h1>
        <h1 *ngIf="manageTutorialHeading">Manage Tutorial</h1>
        <h1 *ngIf="manageConvenienceHeading">Manage Convenience Fee</h1>
        <h1 *ngIf="manageDiseasesHeading">Manage Diseases</h1>
        <h1 *ngIf="dashboardHeading">Dashboard</h1>
        <h1 *ngIf="changePasswordHeading">Change Password</h1>
        <h1 *ngIf="manageTutorialCategory">Manage Tutorial Category</h1>

    </div>


    <div class="right-sec">
        <div class="userName">{{userName}}</div>
        <div class="btn-group" dropdown placement="bottom right">
            <!-- <button id="button-alignment" dropdownToggle type="button" class="dropdown-toggle"
                aria-controls="dropdown-alignment">
                {{userName}}
            </button> -->

            <!-- <ul id="dropdown-alignment" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu"
                aria-labelledby="button-alignment">
                <li role="menuitem"><a class="dropdown-item admin-dropdown"
                        routerLink="/edit-profile">Change Password</a></li>
                <li style="cursor: pointer;" role="menuitem"><a class="dropdown-item admin-dropdown"
                        (click)="openModal(template)">Logout</a></li>
            </ul> -->
        </div>

    </div>
</div>

<!-- Logout fonfirm -->
<ng-template #template>
    <div class="modal-header">
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body text-center delete-modal align-items-center">
        <img src="../../../../../assets/images/create-successfull-img.svg" alt="">
        <p>Are you sure you <br>want to logout?</p>
        <div class="modal-btn d-flex justify-content-around">
            <button class="btn btn-red" (click)="cancel()" style="color: #000;">No</button>
            <button class="btn btn-blue" (click)="confirm()">Yes</button>
        </div>
    </div>
</ng-template>